import React from "react";
import { ListGroup, ListGroupItem, Button, Row, Col } from "reactstrap";
import { Link } from "gatsby";

const Wishlist = ({
  wishlists,
  handleRemoveFromWishlist,
  removeFromWishlistLoading
}) => {
  return (
    <ListGroup>
      {wishlists.map(item => {
        const image = item.image ? item.image : item.images[0];

        let altText = "";
        let sourceUrl = "";
        let srcSet = "";
        if (item.image) {
          altText = image.altText;
          sourceUrl = image.sourceUrl;
          srcSet = image.srcSet;
        } else if (item.images.length) {
          altText = image.alt;
          sourceUrl = image.src;
          srcSet = image.srcSet;
        }

        return (
          <ListGroupItem key={item.databaseId}>
            <Row className="align-items-center">
              {image && (
                <Col xs="12" md="3">
                  <Link to={`/shop/${item.slug}`}>
                    <img
                      src={sourceUrl}
                      srcSet={srcSet}
                      alt={altText}
                      width="220"
                    />
                  </Link>
                </Col>
              )}
              <Col xs="12" md="9">
                <h3>
                  <Link to={`/shop/${item.slug}`}>{item.name}</Link>
                </h3>
                <div
                  className="price mb-3"
                  dangerouslySetInnerHTML={{ __html: item.price_html }}
                ></div>
                <div className="buttons">
                  <Link
                    to={`/shop/${item.slug}`}
                    className="btn btn-primary mr-2"
                  >
                    View Product
                  </Link>
                  <Button
                    color="link"
                    disabled={removeFromWishlistLoading}
                    onClick={() => handleRemoveFromWishlist(item)}
                  >
                    Remove from wishlist
                  </Button>
                </div>
              </Col>
            </Row>
          </ListGroupItem>
        );
      })}
    </ListGroup>
  );
};

export default Wishlist;
